.App {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  background-image: linear-gradient(#E74C3C, #85929E);
}

.updated-time {
  position: absolute;
  top: 0;
  height: 12px;
  padding: 6px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f4f6f7;
  border-radius: 7px;
  background-image: linear-gradient(135deg, #2980b9, #3498db);
}

.updated-time-text {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 11px !important;
  font-weight: 700;
  white-space: pre;
}

h1 {
  position: absolute;
  top: 0;
  text-align: center;
  padding-top: 100px;
  color: #f7f9f9;
}

.machine-light-container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 210px;
  max-height: 210px;
}

h5 {
  text-align: center;
  color: #f7f9f9;
  padding-top: 16px;
  font-size: large;
}

.main-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  padding: 5px;
}

.times-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  padding: 5px;
  margin-bottom: 25px;
}

.glass-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 280px;
  width: 100%;
  padding: 5px;
  margin-top: 25px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 190px;
  max-height: 380px;
  width: 80px;
  padding: 5px;
  border-radius: 11px;
  background-color: #2c3e50;
}

.App-logo {
  height: 7vmin;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 30px;
  padding-top: 30px;
  pointer-events: none;
}

.section {
  position: relative;
  height: 60px;
  width: 80px;
  border-radius: 7px;
  background-color: #95a5a6;
}

.section.color_red {
  background-color: #ff3822;
  box-shadow: 0 0 60px 10px #ff3822;
}

.section.color_yellow {
  background-color: #eeff03;
  box-shadow: 0 0 60px 10px #eeff03;
}

.section.color_green {
  background-color: #06ff59;
  box-shadow: 0 0 60px 10px #06ff59;
}

.glass-card {
  margin: 3px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 7px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.glass-card-label {
  margin-top: 10px;
  text-align: center;
  font-size: medium;
}

.glass-card-description {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  font-size: large;
  color: #E91E63;
}

input[type=text] {
  width: 500px;
  max-width: 500px;
  margin: 10px;
	appearance: none;
	border: none;
	outline: none;
	border-bottom: .2em solid #E91E63;
	background: rgba(#E91E63, .2);
	border-radius: .2em .2em 0 0;
	padding: .4em;
	color: #E91E63;
}

/* CSS */
.button-1 {
  background-color: #EA4C89;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-1:hover,
.button-1:focus {
  background-color: #E74C3C;
}